import clsx from 'clsx';
import type { DetailedHTMLProps, FC, IframeHTMLAttributes } from 'react';

type PlaceMapMode = {
  mapMode: 'place';
  q: string;
  center?: string;
  zoom?: number;
  maptype?: 'roadmap' | 'satellite';
  language?: string;
  region?: string;
};

type ViewMapMode = {
  mapMode: 'view';
  center: string;
  zoom?: number;
  maptype?: 'roadmap' | 'satellite';
  language?: string;
  region?: string;
};

type DirectionsMapMode = {
  mapMode: 'directions';
  origin: string;
  destination: string;
  waypoints?: string[];
  mode?: 'driving' | 'walking' | 'bicycling' | 'transit' | 'flying';
  avoid?: ('tolls' | 'ferries' | 'highways')[];
  units?: 'metric' | 'imperial';
  center?: string;
  zoom?: number;
  maptype?: 'roadmap' | 'satellite';
  language?: string;
  region?: string;
};

type StreetViewMapMode = {
  mapMode: 'streetview';
  heading?: number;
  pitch?: number;
  fov?: number;
  center?: string;
  zoom?: number;
  maptype?: 'roadmap' | 'satellite';
  language?: string;
  region?: string;
};

type SearchMapMode = {
  mapMode: 'search';
  q: string;
  center?: string;
  zoom?: number;
  maptype?: 'roadmap' | 'satellite';
  language?: string;
  region?: string;
};

type MapMode =
  | PlaceMapMode
  | ViewMapMode
  | DirectionsMapMode
  | StreetViewMapMode
  | SearchMapMode;

type GoogleMapsEmbedProps = {
  apiKey: string;
  // eslint-disable-next-line no-undef
  iframeProps?: Partial<
    Omit<
      DetailedHTMLProps<
        IframeHTMLAttributes<HTMLIFrameElement>,
        HTMLIFrameElement
      >,
      'src'
    >
  >;
} & MapMode;

export const GoogleMapsEmbed: FC<GoogleMapsEmbedProps> = ({
  apiKey,
  iframeProps,
  ...restProps
}) => {
  if (!apiKey) throw Error('A Google Maps API key is required');
  if (!restProps.mapMode) throw Error('A map mode is required');

  const {
    className,
    height = 240,
    width = '100%',
    loading = 'lazy',
    allowFullScreen = true,
    referrerPolicy = 'no-referrer-when-downgrade',
  } = iframeProps ?? {};
  const src = new URL('https://www.google.com/');

  src.pathname = `/maps/embed/v1/${restProps.mapMode}`;
  src.searchParams.set('key', apiKey);

  switch (restProps.mapMode) {
    case 'directions': {
      const {
        destination,
        origin,
        center,
        language,
        maptype,
        mode,
        region,
        units,
        zoom,
        avoid = [],
        waypoints = [],
      } = restProps;

      src.searchParams.set('destination', destination);
      src.searchParams.set('origin', origin);

      if (avoid.length) src.searchParams.set('avoid', avoid.join('|'));
      if (center) src.searchParams.set('center', center);
      if (language) src.searchParams.set('language', language);
      if (maptype) src.searchParams.set('maptype', maptype);
      if (mode) src.searchParams.set('mode', mode);
      if (region) src.searchParams.set('region', region);
      if (units) src.searchParams.set('units', units);
      if (waypoints.length)
        src.searchParams.set('waypoints', waypoints.join('|'));
      if (zoom) src.searchParams.set('zoom', zoom.toString());

      break;
    }
    case 'place': {
      const { q, center, language, maptype, region, zoom } = restProps;

      src.searchParams.set('q', q);

      if (center) src.searchParams.set('center', center);
      if (language) src.searchParams.set('language', language);
      if (maptype) src.searchParams.set('maptype', maptype);
      if (region) src.searchParams.set('region', region);
      if (zoom) src.searchParams.set('zoom', zoom.toString());

      break;
    }
    case 'search': {
      const { q, center, language, maptype, region, zoom } = restProps;

      src.searchParams.set('q', q);

      if (center) src.searchParams.set('center', center);
      if (language) src.searchParams.set('language', language);
      if (maptype) src.searchParams.set('maptype', maptype);
      if (region) src.searchParams.set('region', region);
      if (zoom) src.searchParams.set('zoom', zoom.toString());

      break;
    }
    case 'streetview': {
      const { center, fov, heading, language, maptype, pitch, region, zoom } =
        restProps;

      if (center) src.searchParams.set('center', center);
      if (fov) src.searchParams.set('fov', fov.toString());
      if (heading) src.searchParams.set('heading', heading.toString());
      if (language) src.searchParams.set('language', language);
      if (maptype) src.searchParams.set('maptype', maptype);
      if (pitch) src.searchParams.set('pitch', pitch.toString());
      if (region) src.searchParams.set('region', region);
      if (zoom) src.searchParams.set('zoom', zoom.toString());

      break;
    }
    case 'view': {
      const { center, zoom, language, maptype, region } = restProps;

      src.searchParams.set('center', center);

      if (zoom) src.searchParams.set('zoom', zoom.toString());
      if (language) src.searchParams.set('language', language);
      if (maptype) src.searchParams.set('maptype', maptype);
      if (region) src.searchParams.set('region', region);

      break;
    }
    default: {
      throw Error('Invalid map mode');
    }
  }

  return (
    <iframe
      key={src.toString()}
      className={clsx(['border-0', className])}
      height={height}
      width={width}
      loading={loading}
      allowFullScreen={allowFullScreen}
      referrerPolicy={referrerPolicy}
      {...iframeProps}
      src={src.toString()}
    />
  );
};
