import { GoogleMapsEmbed } from '@/components/general/GoogleMapsEmbed';
import { usePreferredLocation } from '@/nanostores/PreferredLocation/hooks';
import type { Location } from '@wavebi/tsunami-cms-admin';
import clsx from 'clsx';
import type { FC } from 'react';
import { useEffect, useMemo, useState } from 'react';

export type FooterMapProps = {
  locations: Location[];
  description?: string;
};

export const FooterMap: FC<FooterMapProps> = ({ description, locations }) => {
  const [preferredLocation] = usePreferredLocation();
  const [isMounted, setIsMounted] = useState(false);
  const selectedLocation = useMemo(
    () => locations[preferredLocation] ?? locations[0],
    [locations, preferredLocation]
  );

  useEffect(() => {
    if (isMounted) return;

    setIsMounted(true);
  }, [isMounted]);

  if (!selectedLocation) return null;

  const { placeId } = selectedLocation ?? {};

  if (!placeId) return null;

  const query = `place_id:${placeId}`;

  if (!isMounted)
    return <div className={clsx('h-[240px]', 'w-full', 'bg-skeleton')}></div>;

  return (
    <div className={clsx(['flex', 'flex-col', 'gap-10'])}>
      <GoogleMapsEmbed
        apiKey={import.meta.env.PUBLIC_GOOGLE_MAPS_API_KEY}
        mapMode="place"
        q={query}
      />

      {!!description?.length && (
        <div
          className={clsx(['[&_a]:underline', '[&_a]:text-accent-1'])}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};
